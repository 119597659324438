
import { ProgramFormula, ProgramType } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgramFormulaSpan extends Vue {
  @Prop({ required: true }) formula!: ProgramFormula;
  @Prop({ default: 'nl-be' }) language!: string;

  get programType() {
        switch (this.formula) {
            case ProgramFormula.ALGEMEEN_OPZEG: {
                return this.language == 'nl-be' ? 'Algemene opzeg' : 'Avis général'
            }
            case ProgramFormula.ALGEMEEN_VERBREKING: {
                return this.language == 'nl-be' ? 'Algemene verbreking' : 'Déconnexion générale'
            }
            case ProgramFormula.BIJZONDER: {
                return this.language == 'nl-be' ? 'Bijzonder' : 'Spécial'
            }
            case ProgramFormula.COLLECTIEF: {
                return this.language == 'nl-be' ? 'Collectief' : 'Collectivement'
            }
            case ProgramFormula.MEDISCH_OVERMACHT: {
                return this.language == 'nl-be' ? 'Medische overmacht' : 'Force majeure médicale'
            }
            case ProgramFormula.VRIJWILLIG: {
                return this.language == 'nl-be' ? 'Vrijwillig' : 'Volontaire'
            }
            default: {
                return this.language == 'nl-be' ? 'Onbekend type' : 'Type inconnu'
            }
           
        }

    }
} 
